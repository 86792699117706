import * as React from 'react';
import { gql } from '@urql/core';
import { useIsMounted } from 'hooks';
import { MainLayout } from 'components/MainLayout';
import { Loader } from 'components/Loader';
import { ContentTitle } from 'components/ContentTitle';
import { useBasicPayMutation, PaymentStatus, useNewOrderPageCheckPaymentQuery } from 'generated/graphql';
import { PageProps } from 'gatsby';
import queryString from 'query-string';
import NotFound from './404';
import { PaymentSber } from './neworder';

const PaymentStatusView = ({ id }: { id: string }) => {
  const [res, reexecute] = useNewOrderPageCheckPaymentQuery({
    variables: { id },
  });

  const status = res.data?.viewer.user?.payment?.status || 'loading';

  React.useEffect(() => {
    if (status !== PaymentStatus.Pending) {
      return undefined;
    }

    const t = setInterval(() => {
      reexecute({
        requestPolicy: 'network-only',
      });
    }, 1000);

    return () => clearInterval(t);
  }, [status, reexecute]);

  if (status === 'loading' || status === PaymentStatus.Pending) {
    return <div>Ждем ответа банка... {status}</div>;
  }

  if (status !== PaymentStatus.PreAuth && status !== PaymentStatus.Success) {
    return <div>Что-то пошло не так... {status}</div>;
  }

  return <div>Платеж успешно принят.</div>;
};

const PayPage = ({ id }: { id: number }) => {
  const [mutationState, mutate] = useBasicPayMutation();
  const [checkedId, setCheckedId] = React.useState<string | null>(null);

  React.useEffect(() => {
    mutate({
      orderId: id,
      useAutoPayment: false,
      rebind: false,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (checkedId) {
    return <PaymentStatusView id={checkedId} />;
  }

  if (mutationState.error) {
    return <div>{mutationState.error.message}</div>;
  }

  if (!mutationState.data || mutationState.fetching) {
    return <Loader />;
  }

  const res = mutationState.data.result;

  if (res.__typename === 'ErrorPayload') {
    return <div>{res.message}</div>;
  }

  if (res.__typename !== 'RequestPaymentUrlPayload') {
    return <div>Что-то пошло не так, попробуйте позже</div>;
  }

  if (!res.url) {
    return <div>Заказ еще не собран или уже оплачен. Мы пришлем смс с ссылкой на оплату после полной сборки</div>;
  }

  const done = () => {
    if (res.payment) {
      setCheckedId(res.payment.id);
    }
  };

  return <PaymentSber url={res.url} height={res.iframeHeight} onDone={done} />;
};

export default ({ location }: PageProps) => {
  const isMounted = useIsMounted();
  const queryParams = queryString.parse(location.search);
  const id = typeof queryParams.id === 'string' ? parseInt(queryParams.id, 10) : 0;

  if (id === 0 || Number.isNaN(id)) {
    return <NotFound />;
  }

  if (!isMounted) return null;

  return (
    <MainLayout>
      <div className="container mb-12 px-2">
        <ContentTitle title={`Оплата заказа №${id}`} />
        <PayPage id={id} />
      </div>
    </MainLayout>
  );
};

gql`
  mutation PayMutation($input: RequestPaymentUrlInput!) {
    result: requestPaymentUrl(input: $input) {
      ... on RequestPaymentUrlPayload {
        url
        iframeHeight
        payment {
          id
          status
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
